import NewsSingleC from "../components/NewsSingle/NewsSingle";

const NewsSingle = () => {
  return (
    <>
      <main>
        <NewsSingleC />
      </main>
    </>
  );
};

export default NewsSingle;
