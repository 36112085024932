import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { sanityClient, urlFor } from "../../../sanity";
import "./Supervisors.scss";

const Supervisors = () => {
  const [supervisors, setSupervisors] = useState([]);
  const [globalState, setGlobalState] = useAppContext();

  useEffect(() => {
    const query = `*[_type == "supervisors"]{
      _id,
      name,
      designation,
      department,
      institution,
      email,
      image
    }`;

    const fetchSupervisors = async () => {
      const supervisors = await sanityClient.fetch(query);
      setSupervisors(supervisors);
      setGlobalState((prevState) => ({
        ...prevState,
        isHomeSupervisorsLoaded: true,
      }));
    };

    fetchSupervisors();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isHomeSupervisorsLoaded: false,
      }));
    };
  }, [setGlobalState]);

  const SupervisorSingle = ({
    image,
    name,
    designation,
    department,
    institution,
  }) => {
    return (
      <div className="supervisor_card">
        <img
          className="supervisor_img"
          src={urlFor(image).url()}
          alt="supervisor_img"
        />
        <div className="supervisor_info_wrapper">
          <div className="name">
            <span>{name}</span>
          </div>
          <p className="designation">{designation}</p>
          {department && <p className="department">{department}</p>}

          <p className="institution">{institution}</p>
        </div>
      </div>
    );
  };

  return (
    <section className="supervisors_section">
      {globalState.isHomeSupervisorsLoaded && (
        <>
          <div className="container_custom">
            <div className="supervisors_container">
              <h1 className="supervisors_title">Supervisors</h1>
              <div className="supervisors_card_wrapper">
                {supervisors?.map((supervisor) => (
                  <SupervisorSingle
                    key={supervisor._id}
                    image={supervisor.image}
                    name={supervisor.name}
                    designation={supervisor.designation}
                    department={supervisor.department}
                    institution={supervisor.institution}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Supervisors;
