import React from "react";
import LabMembersC from "../components/LabMembers/LabMembers";

const LabMembers = () => {
  return (
    <>
      <main>
        <LabMembersC />
      </main>
    </>
  );
};

export default LabMembers;
