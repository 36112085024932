import { Turn as Hamburger } from "hamburger-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import molbioLogo from "../../assets/logo/mol-bio-lab.svg";
import "./MenuBarMobile.scss";

const MenuBarMobile = ({ isHomeUrl, showNavbarBg }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="menubar_mobile_wrapper">
      <div className="logo_n_btn">
        <Link to="/" onClick={() => setOpen(false)}>
          <img
            className={`navbar_logo_img ${showNavbarBg && "img_after_scroll"}`}
            src={molbioLogo}
            alt="mol-bio-lab"
          />
        </Link>
        <Hamburger color={`${isOpen || showNavbarBg || !isHomeUrl ? "black" : "white"}`} toggled={isOpen} toggle={setOpen} rounded />
      </div>
      <div className={`menu_main ${isOpen ? "menu_main_show" : "menu_main_hidden"}`}>
        <div className="menu_main_wrapper">
        <div className="accordion" id="accordionExample">
          <Link to="/" onClick={() => setOpen(false)}>
            <div className="accordion-item accordion_item_custom">
              <h2 className="accordion-header non_accordion" id="headingOne">
                Home
              </h2>
            </div>
          </Link>

          <div className="accordion-item accordion_item_custom">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button accordion_button_custom collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                About Us
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body accordion_body_custom">
                <div className="accordion_body_item">
                  <Link to="/ourlab" onClick={() => setOpen(false)}>Our Lab</Link>
                </div>
                <div className="accordion_body_item">
                  <Link to="/supervisors" onClick={() => setOpen(false)}>Supervisors</Link>
                </div>
                
                <div className="accordion_body_item">
                  <Link to="/labmembers" onClick={() => setOpen(false)}>Lab Members</Link>
                </div>
                <div className="accordion_body_item">
                  <Link to="/gallery" onClick={() => setOpen(false)}>Gallery</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item accordion_item_custom">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button accordion_button_custom collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Research
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body accordion_body_custom">
                <div className="accordion_body_item">
                  <Link to="/current-research" onClick={() => setOpen(false)}>Current Research</Link>
                </div>
                <div className="accordion_body_item">
                  <Link to="/publications" onClick={() => setOpen(false)}>Publications</Link>
                </div>
              </div>
            </div>
          </div>
          
          
          
          
          
          
          <div className="accordion-item accordion_item_custom">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button accordion_button_custom collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                News and Events
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body accordion_body_custom">
                <div className="accordion_body_item">
                  <Link to="/news" onClick={() => setOpen(false)}>News</Link>
                </div>
                <div className="accordion_body_item">
                  <Link to="/molbiolab-in-the-news" onClick={() => setOpen(false)}>Molbiolab in the news</Link>
                </div>
              </div>
            </div>
          </div>




          {/* <Link to="/" onClick={() => setOpen(false)}>
            <div className="accordion-item accordion_item_custom">
              <h2 className="accordion-header non_accordion" id="headingOne">
                News and Events
              </h2>
            </div>
          </Link> */}
        </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBarMobile;
