import { useEffect, useState } from "react";
import PortableText from "react-portable-text";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { sanityClient, urlFor } from "../../sanity";
import Loader from "../Loader";
import "./News.scss";

const News = () => {
  const [news, setNews] = useState([]);
  const [globalState, setGlobalState] = useAppContext();

  useEffect(() => {
    const query = `*[_type == "news"]{
      _id,
      name,
      slug {
        current
      },
      newsDescShort,
      image
    }`;

    const fetchNews = async () => {
      const news = await sanityClient.fetch(query);

      console.log("news", news);
      setNews(news);
      setGlobalState((prevState) => ({
        ...prevState,
        isNewsLoaded: true,
      }));
    };

    fetchNews();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isNewsLoaded: false,
      }));
    };
  }, [setGlobalState]);

  return (
    <section className="news_section">
      {globalState.isNewsLoaded ? (
        <>
          <div className="container_custom">
            <div className="news_container">
              <h1 className="news_section_title">News</h1>

              <div className="news_main">
                {news.map((item) => (
                  <div key={item.name} className="news_block">
                    <div className="news_text_block">
                      <Link to={`/news/${item.slug.current}`}>
                        <h2 className="news_title">{item.name}</h2>
                      </Link>
                      <div className="news_description">
                        {item.newsDescShort?.length > 0 && (
                          <PortableText
                            dataset={process.env.REACT_APP_SANITY_DATASET}
                            projectId={
                              process.env.REACT_APP_SANITY_PROJECT_ID
                            }
                            content={item.newsDescShort}
                            serializers={{
                              h1: (props) => <h1 className="" {...props} />,
                              h2: (props) => <h2 className="" {...props} />,
                              h3: (props) => <h3 className="" {...props} />,
                              h4: (props) => <h4 className="" {...props} />,
                              normal: (props) => {
                                if (props.children[0].length) {
                                  return <p className="" {...props} />;
                                } else {
                                  return <br />;
                                }
                              },
                              li: ({ children }) => (
                                <li className="">{children}</li>
                              ),
                              link: ({ href, children }) => (
                                <a href={href} className="">
                                  {children}
                                </a>
                              ),
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="news_img">
                      <img src={urlFor(item.image).url()} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default News;
