import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import "./MenuBar.scss";
import MenuBarMobile from "./MenuBarMobile";
import MenuBarPC from "./MenuBarPC";

const MenuBar = ({ isHomeUrl }) => {
  const [showSubmenu, setShowSubmenu] = useState(null);
  const [showNavbarBg, setShowNavbarBg] = useState(false);
  const [globalState, setGlobalState] = useAppContext();

  const handleSetShowSubmenu = (val) => {
    setShowSubmenu(val);
  };

  const handleNavbarBg = () => {
    if (window.scrollY >= 80) {
      setShowNavbarBg(true);
    } else {
      setShowNavbarBg(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleNavbarBg);
    return () => {
      window.removeEventListener("scroll", handleNavbarBg);
    };
  }, []);
  return (
    <header
      style={{
        color: `${
          isHomeUrl
            ? !(
                globalState.isHeroSectionBgLoaded &&
                globalState.isHomeAboutLoaded &&
                globalState.isHomeResearchPapersLoaded &&
                globalState.isHomeSupervisorsLoaded
              )
              ? "black"
              : "white"
            : "black"
        }`,
      }}
      className={`${showNavbarBg && "header_style"}`}
      onMouseLeave={() => setShowSubmenu(null)}
    >
      <nav className="navbar_container">
        <div className="menubar_pc">
          <MenuBarPC
            showNavbarBg={showNavbarBg}
            handleSetShowSubmenu={handleSetShowSubmenu}
            showSubmenu={showSubmenu}
          />
        </div>
        <div className="menubar_mobile">
          <MenuBarMobile isHomeUrl={isHomeUrl} showNavbarBg={showNavbarBg} />
        </div>
      </nav>
    </header>
  );
};

export default MenuBar;
