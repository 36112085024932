import React, { useEffect, useState } from "react";
import "./IntroTitle.scss";

const HeaderTitle = ({ delay = 0, title, role }) => {
    const [isShown, setIsShown] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
          setIsShown(true);
        }, delay);
        return () => {
          clearTimeout(timer);
        }
    }, [delay]);
  return (
    <div className="intro_title" aria-label={title} role={role}>
      {isShown && title.split("").map(function (char, index) {
        let header_animation_delay = {
          "animationDelay": 0.5 + index / 10 + "s",
        };
        return (
          <h1 aria-hidden="true" key={index} style={header_animation_delay}>
            {char}
          </h1>
        );
      })}
    </div>
  );
};

export default HeaderTitle;
