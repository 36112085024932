import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import leftArrow from "../../../assets/icons/left_arrow_white.svg";
import rightArrow from "../../../assets/icons/right_arrow_white.svg";
import du_logo from "../../../assets/images/du_logo.png";
import { useAppContext } from "../../../context/AppContext";
import { sanityClient, urlFor } from "../../../sanity";
import "./Intro.scss";
import IntroTitle from "./IntroTitle";

const Intro = () => {
  const [offsetY, setOffsetY] = useState(0);
  const [isDeptShown, setIsDeptShown] = useState(false);
  const [isLogoShown, setIsLogoShown] = useState(false);
  const [bg, setBg] = useState([]);
  const [globalState, setGlobalState] = useAppContext();
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    initialSlide: 0,
  };

  const sliderRef = useRef();

  const handleScroll = () => setOffsetY(window.pageYOffset);

  const setbackgroundImage = (image) => {
    return urlFor(image).url();
  };

  useEffect(() => {
    const query = `*[_type == "heroSectionBg"]{
      _id,
      serial,
      image
    }`;

    const fetchBackgroundImages = async () => {
      const backgroundImages = await sanityClient.fetch(query);
      const sortedArray = backgroundImages.sort((a, b) => a.serial - b.serial);
      setBg(sortedArray);
      setGlobalState((prevState) => ({
        ...prevState,
        isHeroSectionBgLoaded: true,
      }));
    };

    fetchBackgroundImages();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isHeroSectionBgLoaded: false,
      }));
    };
  }, [setGlobalState]);

  useEffect(() => {
    const delays = [
      { time: 2700, setVar: setIsDeptShown, timeOut: null },
      { time: 2750, setVar: setIsLogoShown, timeOut: null },
    ];
    delays.forEach((delay) => {
      delay.timeOut = setTimeout(() => {
        delay.setVar(true);
      }, delay.time);
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      delays.forEach((delay) => {
        clearTimeout(delay.timeOut);
      });
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section className="intro_section">
      {globalState.isHeroSectionBgLoaded && (
        <>
          <div className="intro_container">
            <div className="carousel_custom">
              <Slider ref={sliderRef} {...sliderSettings}>
                {bg?.map((bgSingle) => (
                  <div className="carousel_item_custom" key={bgSingle._id}>
                    <div
                      style={{
                        backgroundImage: `linear-gradient(
                    0deg,
                    rgba(25, 23, 23, 0.35),
                    rgba(25, 23, 23, 0.35)
                  ),
                  url(${setbackgroundImage(bgSingle.image)})`,
                      }}
                      className="carousel_img"
                    ></div>
                  </div>
                ))}
              </Slider>
              <div className="carousel_control_left">
                <button
                  className="control_btn"
                  onClick={() => sliderRef?.current?.slickPrev()}
                >
                  <img className="arrow" src={leftArrow} alt="" />
                </button>
              </div>
              <div className="carousel_control_right">
                <button
                  className="control_btn"
                  onClick={() => sliderRef?.current?.slickNext()}
                >
                  <img className="arrow" src={rightArrow} alt="" />
                </button>
              </div>
            </div>
            <div
              style={{ transform: `translateY(-${offsetY * 0.2}px)` }}
              className="intro_texts"
            >
              <IntroTitle title="MOLECULAR" role="heading" />
              <IntroTitle delay="1000" title="BIOLOGY" role="heading" />
              <IntroTitle delay="1800" title="LAB" role="heading" />
              <div
                className={`intro_subtitle ${
                  isDeptShown && "intro_subtitle_animation"
                }`}
              >
                <h4>Dept. of Biochemistry and Molecular Biology</h4>
                <h4>University of Dhaka</h4>
              </div>
              <div
                className={`intro_du_logo ${
                  isLogoShown && "intro_logo_animation"
                }`}
              >
                <img src={du_logo} alt="" />
              </div>
            </div>
          </div>
          <div className="top_circle_container">
            <div className="top_circle"></div>
          </div>
          <div className="bottom_circle_container">
            <div className="bottom_circle"></div>
          </div>
        </>
      )}
    </section>
  );
};

export default Intro;
