import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { sanityClient, urlFor } from "../../sanity";
import Loader from "../Loader";
import "./Gallery.scss";

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const [globalState, setGlobalState] = useAppContext();

  useEffect(() => {
    const query = `*[_type == "gallery"]{
      _id,
      name,
      image
    }`;

    const fetchGallery = async () => {
      const gallery = await sanityClient.fetch(query);
      setGallery(gallery);
      setGlobalState((prevState) => ({
        ...prevState,
        isGalleryLoaded: true,
      }));
    };

    fetchGallery();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isGalleryLoaded: false,
      }));
    };
  }, [setGlobalState]);

  const Photo = ({ image }) => {
    return (
      <div className="photo_container">
        <img src={urlFor(image).url()} alt="" />
      </div>
    );
  };
  return (
    <section className="gallery_page_section">
      {globalState.isGalleryLoaded ? (
        <>
          <div className="container_custom">
            <div className="gallery_page_container">
              <h1 className="gallery_page_title">Gallery</h1>
              <div className="gallery_page_main">
                {gallery.length > 0 &&
                  gallery.map((photo) => (
                    <Photo key={photo._id} image={photo.image} />
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default Gallery;
