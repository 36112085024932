import { useEffect, useState } from "react";
import PortableText from "react-portable-text";
import { useAppContext } from "../../context/AppContext";
import { sanityClient } from "../../sanity";
import Loader from "../Loader";
import "./OurLab.scss";

const OurLab = () => {
  const [labAbout, setLabAbout] = useState(null);
  const [globalState, setGlobalState] = useAppContext();

  useEffect(() => {
    const query = `*[_type == "aboutOurLab"]{
      _id,
      ourLabShort,
      ourLabLong
    }`;

    const fetchLabDetails = async () => {
      const ourLab = await sanityClient.fetch(query);
      setLabAbout(ourLab[0].ourLabLong);
      setGlobalState((prevState) => ({
        ...prevState,
        isOurLabLoaded: true,
      }));
    };

    fetchLabDetails();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isOurLabLoaded: false,
      }));
    };
  }, [setGlobalState]);

  return (
    <section className="ourlab">
      {globalState.isOurLabLoaded ? (
        <>
          <div className="container_custom">
            <div className="ourlab_container">
              <h1 className="ourlab_title">Our Lab</h1>
              <div className="ourlab_main">
                {labAbout?.length > 0 && (
                  <PortableText
                    dataset={process.env.REACT_APP_SANITY_DATASET}
                    projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                    content={labAbout}
                    serializers={{
                      h1: (props) => (
                        <h1 className="ourlab_main_text" {...props} />
                      ),
                      h2: (props) => (
                        <h2 className="ourlab_main_text" {...props} />
                      ),
                      h3: (props) => (
                        <h3 className="ourlab_main_text" {...props} />
                      ),
                      h4: (props) => (
                        <h4 className="ourlab_main_text" {...props} />
                      ),
                      normal: (props) => {
                        if (props.children[0].length) {
                          return <p className="ourlab_main_text" {...props} />;
                        } else {
                          return <br />;
                        }
                      },
                      li: ({ children }) => (
                        <li className="ourlab_main_text">{children}</li>
                      ),
                      link: ({ href, children }) => (
                        <a
                          href={href}
                          className="ourlab_main_text hover:underline"
                        >
                          {children}
                        </a>
                      ),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default OurLab;
