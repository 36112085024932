import { useEffect, useState } from "react";
import PortableText from "react-portable-text";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { sanityClient } from "../../sanity";
import Loader from "../Loader";
import "./NewsSingle.scss";

const NewsSingle = () => {
  const [singleNews, setSingleNews] = useState([]);
  const [globalState, setGlobalState] = useAppContext();
  let { slug } = useParams();

  useEffect(() => {
    const query = `*[_type == "news" && slug.current == "${slug}"][0]{
      _id,
      name,
      newsDescLong
    }`;

    console.log('query', query)

    const fetchNews = async () => {
      const singleNews = await sanityClient.fetch(query);

      console.log("singleNews", singleNews);
      setSingleNews(singleNews);
      setGlobalState((prevState) => ({
        ...prevState,
        isSingleNewsLoaded: true,
      }));
    };

    fetchNews();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isSingleNewsLoaded: false,
      }));
    };
  }, [setGlobalState, slug]);

  return (
    <section className="news_single_section">
      {globalState.isSingleNewsLoaded ? (
        <>
          <div className="container_custom">
            <div className="news_single_container">
              <div className="news_single_main">
                <div className="news_single_block">
                  <h2 className="news_single_title">{singleNews.name}</h2>
                  <div className="news_single_description">
                    {singleNews.newsDescLong?.length > 0 && (
                      <PortableText
                        dataset={process.env.REACT_APP_SANITY_DATASET}
                        projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                        content={singleNews.newsDescLong}
                        serializers={{
                          h1: (props) => <h1 className="" {...props} />,
                          h2: (props) => <h2 className="" {...props} />,
                          h3: (props) => <h3 className="" {...props} />,
                          h4: (props) => <h4 className="" {...props} />,
                          normal: (props) => {
                            if (props.children[0].length) {
                              return <p className="" {...props} />;
                            } else {
                              return <br />;
                            }
                          },
                          li: ({ children }) => (
                            <li className="">{children}</li>
                          ),
                          link: ({ href, children }) => (
                            <a href={href} className="">
                              {children}
                            </a>
                          ),
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default NewsSingle;
