import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import leftArrow from "../../../assets/icons/left_arrow_green.svg";
import rightArrow from "../../../assets/icons/right_arrow_green.svg";
import { useAppContext } from "../../../context/AppContext";
import { sanityClient, urlFor } from "../../../sanity";
import "./Papers.scss";

const Papers = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [papers, setPapers] = useState([]);
  const [globalState, setGlobalState] = useAppContext();

  useEffect(() => {
    const query = `*[_type == "researchPaper"]{
      _id,
      title,
      link,
      authors,
      image
    }`;

    const fetchPapers = async () => {
      const supervisors = await sanityClient.fetch(query);
      setPapers(supervisors);
      setGlobalState((prevState) => ({
        ...prevState,
        isHomeResearchPapersLoaded: true,
      }));
    };

    fetchPapers();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isHomeResearchPapersLoaded: false,
      }));
    };
  }, [setGlobalState]);

  const slider = useRef();

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    initialSlide: 0,
    beforeChange: (oldIndex, newIndex) => setSliderIndex(newIndex),
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const PaperCard = ({ image, title, authors, link }) => {
    const paper_img = { backgroundImage: `url(${urlFor(image).url()})` };
    const truncate = (string, n) => {
      return string?.length > n ? string.substr(0, n - 1) + "..." : string;
    };

    return (
      <div className="paper_card_wrapper">
        <a
          className="paper_card_link"
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          <div className="paper_card">
            <div style={paper_img} className="paper_img"></div>
            <div className="paper_card_body">
              <p className="paper_title">{title}</p>

              <p className="paper_authors">{truncate(authors, 95)}</p>
            </div>
          </div>
        </a>
      </div>
    );
  };
  return (
    <section className="papers_section">
      {globalState.isHomeResearchPapersLoaded && (
        <>
          <div className="container_custom">
            {papers.length > 0 && (
              <div className="papers_container">
                <h1 className="papers_title">Key Research Papers</h1>
                <div className="papers_slider_container">
                  <Slider ref={slider} {...sliderSettings}>
                    {papers?.map((paper) => (
                      <PaperCard
                        key={paper._id}
                        image={paper.image}
                        title={paper.title}
                        authors={paper.authors}
                        link={paper.link}
                      />
                    ))}
                  </Slider>
                </div>
                <div className="indicator">
                  <button
                    className="control_btn"
                    onClick={() => slider?.current?.slickPrev()}
                    aria-label="Previous"
                  >
                    <img className="arrow_left" src={leftArrow} alt="" />
                  </button>

                  <div className="slide_dots_container">
                    {papers?.map((testimonial, index) => (
                      <div
                        key={testimonial._id}
                        className={`indicator_circle ${
                          sliderIndex === index ? "circle_bg" : "circle_border"
                        }`}
                        onClick={() => {
                          slider?.current?.slickGoTo(index);
                        }}
                      />
                    ))}
                  </div>

                  <button
                    className="control_btn"
                    onClick={() => slider?.current?.slickNext()}
                    aria-label="Next"
                  >
                    <img className="arrow_right" src={rightArrow} alt="" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default Papers;
