import React from 'react';
import GalleryC from "../components/Gallery/Gallery";

const Gallery = () => {
    return (
        <>
            <main>
                <GalleryC/>
            </main>
        </>
    );
};

export default Gallery;