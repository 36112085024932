import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import CurrentResearch from "./components/CurrentResearch/CurrentResearch";
import Contact from "./components/Home/Contact/Contact";
import MenuBar from "./components/MenuBar/MenuBar";
import ScrollToTop from "./helpers/ScrollToTop";
import Gallery from "./screens/Gallery";
import Home from "./screens/Home";
import LabMembers from "./screens/LabMembers";
import MolbiolabInTheNews from "./screens/MolbiolabInTheNews";
import News from "./screens/News";
import NewsSingle from "./screens/NewsSingle";
import OurLab from "./screens/OurLab";
import Publications from "./screens/Publications";
import Supervisors from "./screens/Supervisors";

function App() {
  const location = useLocation();
  const [isHomeUrl, setIsHomeUrl] = useState(false);
  useEffect(() => {
    if ((location.pathname === "/")) {
      setIsHomeUrl(true);
    }
    else {
      setIsHomeUrl(false);
    }
    return () => {
      setIsHomeUrl(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if(window.innerWidth > 767.98) {
      document.body.style.height = "100%";
    }
  })
  
  return (
    <div className="container_all">
      <MenuBar isHomeUrl={isHomeUrl} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ourlab" element={<OurLab />} />
        <Route path="/supervisors" element={<Supervisors />} />
        <Route path="/labmembers" element={<LabMembers />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/current-research" element={<CurrentResearch />} />
        <Route path="/publications" element={<Publications />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:slug" element={<NewsSingle />} />
        <Route path="/molbiolab-in-the-news" element={<MolbiolabInTheNews />} />
      </Routes>
      <Contact />
    </div>
  );
}

export default App;
