import React from 'react';
import SupervisorsC from "../components/Supervisors/Supervisors";

const Supervisors = () => {
    return (
        <>
            <main>
                <SupervisorsC/>
            </main>
        </>
    );
};

export default Supervisors;