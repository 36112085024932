import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { sanityClient, urlFor } from "../../sanity";
import Loader from "../Loader";
import "./LabMembers.scss";

const labmemberBtns = [
  {
    id: 0,
    btnShortName: "current",
    btnName: "Current Members",
  },
  {
    id: 1,
    btnShortName: "alumni",
    btnName: "Alumni Members",
  },
];

const LabMembers = () => {
  const [btnClicked, setBtnClicked] = useState("current");
  const [currentLabMembers, setCurrentLabMembers] = useState([]);
  const [alumniLabMembers, setAlumniLabMembers] = useState([]);
  const [globalState, setGlobalState] = useAppContext();

  const queryToGetCurrentLabMembers = `
    *[_type == "labMembersCurrent"]{
      _id,
      serial,
      fullName,
      designation,
      session,
      email,
      image
    }`;

  const queryToGetAlumniLabMembers = `
    *[_type == "labMembersAlumni"]{
      _id,
      serial,
      fullName,
      designation,
      session,
      email,
      image
    }`;

  useEffect(() => {
    const fetchCurrentLabMembers = async (query) => {
      const labMembers = await sanityClient.fetch(query);
      const sortedArray = labMembers.sort((a, b) => a.serial - b.serial);
      setCurrentLabMembers(sortedArray);
      setGlobalState((prevState) => ({
        ...prevState,
        isCurrentLabMembersLoaded: true,
      }));
    };

    const fetchAlumniLabMembers = async (query) => {
      const labMembers = await sanityClient.fetch(query);
      const sortedArray = labMembers.sort((a, b) => a.serial - b.serial);
      setAlumniLabMembers(sortedArray);
      setGlobalState((prevState) => ({
        ...prevState,
        isAlumniLabMembersLoaded: true,
      }));
    };

    fetchCurrentLabMembers(queryToGetCurrentLabMembers);
    fetchAlumniLabMembers(queryToGetAlumniLabMembers);

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isCurrentLabMembersLoaded: false,
        isAlumniLabMembersLoaded: false,
      }));
    };
  }, [setGlobalState, queryToGetCurrentLabMembers, queryToGetAlumniLabMembers]);

  const handleBtnClicked = (val) => {
    // setLabMembers([]);
    setBtnClicked(val);
  };
  const LabmembersBtnSingle = ({
    btnShortName,
    btnName,
    btnClicked,
    handleBtnClicked,
  }) => {
    return (
      <button
        className={`labmembers_btn ${
          btnClicked === btnShortName && "labmembers_btn_clicked"
        }`}
        onClick={() => {
          handleBtnClicked(btnShortName);
        }}
      >
        {btnName}
      </button>
    );
  };

  const MemberSingle = ({ member }) => {
    return (
      <div className="col-12 col-sm-md-4 col-md-3">
        <div className="member_card">
          <img
            className="member_img"
            src={urlFor(member.image).url()}
            alt="member_img"
          />
          <div className="member_info_wrapper">
            <div className="name">
              <span>{member.fullName}</span>
            </div>
            <p className="designation">{member.designation}</p>
            <p className="session">{member.session}</p>
            <div className="email">
              <span>{member.email}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MembersAll = ({ labMembers }) => {
    return (
      <div className="row justify-content-center labmembers_card_container">
        {labMembers.length > 0 &&
          labMembers.map((member) => (
            <MemberSingle key={member._id} member={member} />
          ))}
      </div>
    );
  };
  return (
    <section className="labmembers_page_section">
      {globalState.isCurrentLabMembersLoaded &&
      globalState.isAlumniLabMembersLoaded ? (
        <>
          <div className="container_custom labmembers_page_container">
            <h1 className="labmembers_page_title">Lab Members</h1>
            <div className="labmembers_page_main">
              <div className="labmembers_btn_wrapper">
                {labmemberBtns.map((btn) => (
                  <LabmembersBtnSingle
                    key={btn.id}
                    btnShortName={btn.btnShortName}
                    btnName={btn.btnName}
                    btnClicked={btnClicked}
                    handleBtnClicked={handleBtnClicked}
                  />
                ))}
              </div>
              <div className="labmembers_card_wrapper">
                {btnClicked === "current" && (
                  <MembersAll labMembers={currentLabMembers} />
                )}
                {btnClicked === "alumni" && (
                  <MembersAll labMembers={alumniLabMembers} />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default LabMembers;
