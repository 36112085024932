import React from "react";
import About from "../components/Home/About/About";
import Intro from "../components/Home/Intro/Intro";
import Papers from "../components/Home/Papers/Papers";
import Supervisors from "../components/Home/Supervisors/Supervisors";
import Loader from "../components/Loader";
import { useAppContext } from "../context/AppContext";

const Home = () => {
  const [globalState, setGlobalState] = useAppContext();

  return (
    <>
      <main>
        {!(globalState.isHeroSectionBgLoaded &&
          globalState.isHomeAboutLoaded &&
          globalState.isHomeResearchPapersLoaded &&
          globalState.isHomeSupervisorsLoaded) && (
              <Loader/>
          )}
        <>
          <Intro />
          <About />
          <Papers />
          <Supervisors />
        </>
      </main>
    </>
  );
};

export default Home;
