import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { sanityClient } from "../../sanity";
import Loader from "../Loader";
import "./Publications.scss";

const Publications = () => {
  const [publications, setPublications] = useState([]);
  const [globalState, setGlobalState] = useAppContext();

  useEffect(() => {
    const query = `*[_type == "publications"]{
      _id,
      serial,
      paperName,
      link,
      authors
    }`;

    const fetchPublications = async () => {
      const publications = await sanityClient.fetch(query);
      const sortedArray = publications.sort((a, b) => a.serial - b.serial);
      setPublications(sortedArray);
      setGlobalState((prevState) => ({
        ...prevState,
        isPublicationsLoaded: true,
      }));
    };

    fetchPublications();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isGalleryLoaded: false,
      }));
    };
  }, [setGlobalState]);

  return (
    <section className="publications_section">
      {globalState.isPublicationsLoaded ? (
        <>
          <div className="container_custom">
            <div className="publications_container">
              <h1 className="publications_title">Publications</h1>
              <div className="publications">
                {publications.map((item, index) => (
                  <div key={item._id} className="publications_block">
                    <div className="numbering">{index + 1}.</div>
                    <div className="publications_main">
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <div className="paper_title">{item.paperName}</div>
                      </a>

                      <div className="paper_authors">{item.authors}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default Publications;
