import React from "react";
import "./Loader.scss";

export default function Loader() {
  return (
    <div className="loader_wrapper">
      <div className="loader_container">
        <div className="loader_inside">
          <div className="loading"></div>
        </div>
      </div>
    </div>
  );
}
