import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { sanityClient, urlFor } from "../../sanity";
import Loader from "../Loader";
import "./MolbiolabInTheNews.scss";

const MolbiolabInTheNews = () => {
  const [newsResult, setNewsResult] = useState([]);
  const [videoResult, setVideoResult] = useState([]);
  const [globalState, setGlobalState] = useAppContext();

  useEffect(() => {
    const queryNews = `*[_type == "newsAndEventsNews"]{
      _id,
      name,
      url,
      image
    }`;

    const queryVideos = `*[_type == "newsAndEventsVideos"]{
      _id,
      name,
      url,
    }`;

    const fetchMolbiolabInTheNews = async () => {
      const newsResult = await sanityClient.fetch(queryNews);
      const videoResult = await sanityClient.fetch(queryVideos);

      setNewsResult(newsResult);
      setVideoResult(videoResult);

      setGlobalState((prevState) => ({
        ...prevState,
        isMolbiolabInTheNewsLoaded: false,
      }));
    };

    fetchMolbiolabInTheNews();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isMolbiolabInTheNewsLoaded: false,
      }));
    };
  }, [setGlobalState]);

  return (
    <section className="molbionews_section">
      {!globalState.isMolbiolabInTheNewsLoaded ? (
        <>
          <div className="container_custom">
            <div className="molbionews_container">
              <h1 className="molbionews_section_title">
                Molbiolab In The News
              </h1>

              <div className="molbionews_main">
                <div className="molbionews_articles_container">
                  <div className="molbionews_articles_container_title">
                    News and Articles
                  </div>
                  <div className="molbionews_articles_container_inner">
                    {newsResult.map((item) => (
                      <div key={item} className="molbionews_article">
                        <div
                          style={{
                            backgroundImage: `url(${urlFor(item.image).url()})`,
                          }}
                          className="molbionews_article_img"
                        ></div>
                        <Link to={item.url}>
                          <h2 className="molbionews_article_title">
                            {item.name}
                          </h2>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="videos_container">
                  <div className="videos_container_title">Videos</div>
                  <div className="videos_container_inner">
                    {videoResult.map((item) => (
                      <div key={item} className="video_item">
                        <div className="video_item_inner">
                          <iframe
                            width="100%"
                            height="230px"
                            className="video"
                            src={item.url}
                          ></iframe>
                        </div>

                        <h2 className="video_title">{item.name}</h2>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default MolbiolabInTheNews;
