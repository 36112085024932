import { Link } from "react-router-dom";
import fbIcon from "../../../assets/icons/fb_icon.svg";
import lkdIcon from "../../../assets/icons/lkd_icon.svg";
import rgIcon from "../../../assets/icons/rg_icon.svg";
import duLogo from "../../../assets/logo/du_logo.svg";
import molBioLogo from "../../../assets/logo/mol-bio-lab.svg";
import "./Contact.scss";

const Contact = () => {
  return (
    <section className="contact_section">
      <div className="container_custom">
        <div className="contact_container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact_us_wrapper">
                <p className="contact_title_common">Contact Us</p>
                <div className="contact_us_info">
                  <p>Phone: 880-2-9661900-ext (7645)</p>
                  <a href={`mailto:info@molbiolab.du.ac.bd`}>
                    Email: info@molbiolab.du.ac.bd
                  </a>
                </div>
                <p className="follow_us_title contact_title_common">
                  Follow Us
                </p>
                <div className="follow_us_icons">
                  <a
                    href="https://www.facebook.com/Molecular-Biology-Lab-132566566820274"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={fbIcon} alt="facebook" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/molbiolabdu/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={lkdIcon} alt="linkedin" />
                  </a>
                  <a
                    href="https://www.researchgate.net/lab/Molecular-Biology-Lab-Haseena-Khan?fbclid=IwAR3Ae-nof8pi7-g3BWcqkZrdnRD1YhLuE7zEzhpH_rweXavrhc-E18X0pkI"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={rgIcon} alt="researchGate" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="quick_links_wrapper">
                <p className="contact_title_common">Quick Links</p>
                <div className="quick_links_info">
                  <p>
                    <Link to="/ourlab">About our lab</Link>
                  </p>
                  <p>
                    <Link to="/labmembers">Our Team</Link>
                  </p>
                  <p>
                    <Link to="/publications">Publications</Link>
                  </p>
                  <p>
                    <Link to="/">News and Events</Link>
                  </p>
                  <p>
                    <Link to="/gallery">Gallery</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="find_us_wrapper">
                <p className="contact_title_common">Find Us</p>
                <div className="find_us_info">
                  Molecular Biology Laboratory, <br /> Dept. of Biochemistry and
                  Molecular Biology, <br /> University of Dhaka, Dhaka-1000.
                </div>
                <div className="institution_logo">
                  <img src={molBioLogo} alt="" />

                  <a href="https://du.ac.bd/" target="_blank" rel="noreferrer">
                    <img src={duLogo} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div>
              Copyright © {new Date().getFullYear()} - Moleculer Biology Lab |
              All Rights Reserved
            </div>
            <div className="hr_line"></div>
            <div className="creator_1">
              Designed by Md Nazid Bin Ibrahim and Developed by{" "}
              <a
                href="https://www.kaiserahmed.net/"
                target="_blank"
                rel="noreferrer"
              >
                S M Kaiser Ahmed
              </a>
            </div>
            <div className="creator_2">
              <div>Designed by Md Nazid Bin Ibrahim</div>
              <div>
                Developed by{" "}
                <a
                  href="https://www.kaiserahmed.xyz/"
                  target="_blank"
                  rel="noreferrer"
                >
                  S M Kaiser Ahmed
                </a>{" "}
              </div>
            </div>
          </footer>
        </div>
      </div>
    </section>
  );
};

export default Contact;
