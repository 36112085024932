import { useEffect, useState } from "react";
import PortableText from "react-portable-text";
import { useAppContext } from "../../context/AppContext";
import { sanityClient, urlFor } from "../../sanity";
import Loader from "../Loader";
import "./CurrentResearch.scss";

const CurrentResearch = () => {
  const [currentResearch, setCurrentResearch] = useState([]);
  const [globalState, setGlobalState] = useAppContext();

  useEffect(() => {
    const query = `*[_type == "currentResearch"]| order(_createdAt desc){
      _id,
      name,
      body,
      image
    }`;

    const fetchNews = async () => {
      const currentResearch = await sanityClient.fetch(query);

      console.log("news", currentResearch);
      setCurrentResearch(currentResearch);
      setGlobalState((prevState) => ({
        ...prevState,
        isCurrentResearchLoaded: true,
      }));
    };

    fetchNews();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isCurrentResearchLoaded: false,
      }));
    };
  }, [setGlobalState]);

  return (
    <section className="current_research_section">
      {!globalState.isGalleryLoaded ? (
        <>
          <div className="container_custom">
            <div className="current_research_container">
              <h1 className="current_research_title">Current Research</h1>
              <div className="current_research_main">
                {currentResearch.map((item) => (
                  <div key={item._id} className="research_block">
                    <div className="research_text_block">
                      <div className="research_title">
                      {item.name}
                      </div>
                      <div className="research_description">
                      {item.body?.length > 0 && (
                          <PortableText
                            dataset={process.env.REACT_APP_SANITY_DATASET}
                            projectId={
                              process.env.REACT_APP_SANITY_PROJECT_ID
                            }
                            content={item.body}
                            serializers={{
                              h1: (props) => <h1 className="" {...props} />,
                              h2: (props) => <h2 className="" {...props} />,
                              h3: (props) => <h3 className="" {...props} />,
                              h4: (props) => <h4 className="" {...props} />,
                              normal: (props) => {
                                if (props.children[0].length) {
                                  return <p className="" {...props} />;
                                } else {
                                  return <br />;
                                }
                              },
                              li: ({ children }) => (
                                <li className="">{children}</li>
                              ),
                              link: ({ href, children }) => (
                                <a href={href} className="">
                                  {children}
                                </a>
                              ),
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="research_img">
                      <img
                        src={urlFor(item.image).url()}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default CurrentResearch;
