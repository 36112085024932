import MolbiolabInTheNewsC from "../components/MolbiolabInTheNews/MolbiolabInTheNews";

const MolbiolabInTheNews = () => {
  return (
    <>
      <main>
        <MolbiolabInTheNewsC />
      </main>
    </>
  );
};

export default MolbiolabInTheNews;
