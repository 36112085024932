import NewsC from "../components/News/News";

const News = () => {
  return (
    <>
      <main>
        <NewsC />
      </main>
    </>
  );
};

export default News;
