import { useEffect, useState } from "react";
import PortableText from "react-portable-text";
import { useAppContext } from "../../context/AppContext";
import { sanityClient, urlFor } from "../../sanity";
import Loader from "../Loader";
import "./Supervisors.scss";

const Supervisors = () => {
  const [btnClicked, setBtnClicked] = useState(0);
  const [supervisors, setSupervisors] = useState([]);
  const [globalState, setGlobalState] = useAppContext();

  const handleSetBtnClicked = (val) => {
    setBtnClicked(val);
  };

  useEffect(() => {
    const query = `*[_type == "supervisors"]{
      _id,
      name,
      designation,
      department,
      institution,
      email,
      about,
      image
    }`;

    const fetchSupervisors = async () => {
      const supervisors = await sanityClient.fetch(query);
      setSupervisors(supervisors);
      setGlobalState((prevState) => ({
        ...prevState,
        isSupervisorsLoaded: true,
      }));
    };

    fetchSupervisors();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isSupervisorsLoaded: false,
      }));
    };
  }, [setGlobalState]);

  const SupervisorBtnSingle = ({
    supervisorId,
    name,
    btnClicked,
    handleSetBtnClicked,
  }) => {
    return (
      <button
        className={`supervisor_btn ${
          btnClicked === supervisorId && "supervisor_btn_clicked"
        }`}
        onClick={() => handleSetBtnClicked(supervisorId)}
      >
        {name}
      </button>
    );
  };

  const SupervisorSingle = ({
    supervisorId,
    name,
    email,
    designation,
    department,
    image,
    institution,
    about,
  }) => {
    return (
      <div className="supervisor_single_container container_custom">
        <div className="supervisor_info">
          <div className="row">
            <div className="col-md-2">
              <div className="supervisor_img_wrapper">
                <img
                  className="img-fluid supervisor_img"
                  src={urlFor(image).url()}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-10">
              <div className="info_texts">
                <div className="info_texts_wrapper">
                  <h2 className="title">{name}</h2>
                  <p className="designation">{designation}</p>
                  <p className="institution">{institution}</p>
                  <small className="email">{email}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="supervisor_details">
          <PortableText
            dataset={process.env.REACT_APP_SANITY_DATASET}
            projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
            content={about}
            serializers={{
              h1: (props) => (
                <h1 className="supervisor_details_text" {...props} />
              ),
              h2: (props) => (
                <h2 className="supervisor_details_text" {...props} />
              ),
              h3: (props) => (
                <h3 className="supervisor_details_text" {...props} />
              ),
              h4: (props) => (
                <h4 className="supervisor_details_text" {...props} />
              ),
              normal: (props) => {
                if (props.children[0].length) {
                  return <p className="supervisor_details_text" {...props} />;
                } else {
                  return <br />;
                }
              },
              li: ({ children }) => (
                <li className="supervisor_details_text">{children}</li>
              ),
              link: ({ href, children }) => (
                <a
                  href={href}
                  className="supervisor_details_text hover:underline"
                >
                  {children}
                </a>
              ),
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <section className="supervisors_page_section">
      {globalState.isSupervisorsLoaded ? (
        <>
          <div className="container_custom">
            <div className="supervisors_page_container">
              <h1 className="supervisors_page_title">Supervisors</h1>
              {supervisors.length > 0 && (
                <div className="supervisors_page_main">
                  <div className="supervisors_btn_wrapper">
                    {supervisors.map((supervisor, index) => (
                      <SupervisorBtnSingle
                        key={supervisor._id}
                        supervisorId={index}
                        name={supervisor.name}
                        btnClicked={btnClicked}
                        handleSetBtnClicked={handleSetBtnClicked}
                      />
                    ))}
                  </div>

                  {
                    <SupervisorSingle
                      key={supervisors[btnClicked]._id}
                      supervisorId={supervisors[btnClicked]._id}
                      name={supervisors[btnClicked].name}
                      email={supervisors[btnClicked].email}
                      designation={supervisors[btnClicked].designation}
                      department={supervisors[btnClicked].department}
                      about={supervisors[btnClicked].about}
                      institution={supervisors[btnClicked].institution}
                      image={supervisors[btnClicked].image}
                    />
                  }
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default Supervisors;
