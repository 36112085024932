import { useEffect, useState } from "react";
import PortableText from "react-portable-text";
import { Link } from "react-router-dom";
import about_img from "../../../assets/images/about_img.png";
import { useAppContext } from "../../../context/AppContext";
import { sanityClient } from "../../../sanity";
import "./About.scss";

const About = () => {
  const [labAbout, setLabAbout] = useState(null);
  const [globalState, setGlobalState] = useAppContext();

  useEffect(() => {
    const query = `*[_type == "aboutOurLab"]{
      _id,
      ourLabShort
    }`;

    const fetchLabAbout = async () => {
      const ourLab = await sanityClient.fetch(query);
      setLabAbout(ourLab[0].ourLabShort);
      setGlobalState((prevState) => ({
        ...prevState,
        isHomeAboutLoaded: true,
      }));
    };

    fetchLabAbout();

    return () => {
      setGlobalState((prevState) => ({
        ...prevState,
        isHomeAboutLoaded: false,
      }));
    };
  }, [setGlobalState]);

  const seeMoreTextBlock = () => {
    return (
      <span className="about_text">
        <Link to="/ourlab">
          <span>see more</span>
        </Link>
      </span>
    );
  };

  return (
    <section className="about_section">
      {globalState.isHomeAboutLoaded && (
        <>
          <div className="about_container">
            <div className="about_inner">
              <div className="col-md-6 about_right_container">
                <h1 className="about_title">
                  WHO <br /> WE <br /> ARE
                </h1>
                <img
                  className="img-fluid about_img"
                  src={about_img}
                  alt="about_img"
                />
              </div>
              <div className="col-md-6 about_text_container">
                {labAbout?.length > 0 && (
                  <PortableText
                    dataset={process.env.REACT_APP_SANITY_DATASET}
                    projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                    content={labAbout}
                    serializers={{
                      h1: (props) => <h1 className="about_text" {...props} />,
                      h2: (props) => <h2 className="about_text" {...props} />,
                      h3: (props) => <h3 className="about_text" {...props} />,
                      h4: (props) => <h4 className="about_text" {...props} />,
                      normal: (props) => {
                        if (props.children[0].length) {
                          return <p className="about_text" {...props} />;
                        } else {
                          return <br />;
                        }
                      },
                      li: ({ children }) => (
                        <li className="about_text">{children}</li>
                      ),
                      link: ({ href, children }) => (
                        <a href={href} className="about_text hover:underline">
                          {children}
                        </a>
                      ),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default About;
