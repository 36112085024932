import { createContext, useContext, useState } from "react";

const AppContext = createContext();

export default function AppWrapper({ children }) {
  const [globalState, setGlobalState] = useState({
    isHeroSectionBgLoaded: false,
    isHomeAboutLoaded: false,
    isHomeResearchPapersLoaded: false,
    isHomeSupervisorsLoaded: false,
    isOurLabLoaded: false,
    isSupervisorsLoaded: false,
    isGalleryLoaded: false,
    isCurrentLabMembersLoaded: false,
    isAlumniLabMembersLoaded: false,
    isPublicationsLoaded: false,
    isNewsLoaded: false,
    isSingleNewsLoaded: false,
    isMolbiolabInTheNewsLoaded: false,
    isCurrentResearchLoaded: false,
  });
  return (
    <AppContext.Provider value={[globalState, setGlobalState]}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
