import { Link } from "react-router-dom";
import molbioLogo from "../../assets/logo/mol-bio-lab.svg";
import "./MenuBarPC.scss";

const menu = {
  aboutUs: {
    id: 1,
    items: [
      {
        id: 11,
        itemName: "Our lab",
        link: "/ourlab",
      },
      {
        id: 12,
        itemName: "Supervisors",
        link: "/supervisors",
      },
      {
        id: 13,
        itemName: "Lab members",
        link: "/labmembers",
      },
      {
        id: 14,
        itemName: "Gallery",
        link: "/gallery",
      },
    ],
    xPosition: -14.5,
  },
  research: {
    id: 2,
    items: [
      {
        id: 21,
        itemName: "Current Research",
        link: "/current-research",
      },
      {
        id: 22,
        itemName: "Publications",
        link: "/publications",
      },
    ],
    xPosition: -4.1,
  },
  newsAndEvents: {
    id: 3,
    items: [
      {
        id: 31,
        itemName: "News",
        link: "/news",
      },
      {
        id: 32,
        itemName: "Molbiolab in the news",
        link: "/molbiolab-in-the-news",
      },
    ],
    xPosition: 0,
  },
};

const MenuBarPC = ({ showNavbarBg, handleSetShowSubmenu, showSubmenu }) => {
  const LiItemGenerator = ({ item }) => {
    return (
      <li className="navbar_item navbar_menu_item">
        <Link to={item.link}>{item.itemName}</Link>
      </li>
    );
  };

  const SubMenu = ({ subMenu }) => {
    const wrapperStyle = {
      transform: `translateY(${subMenu.yPosition || -0.6}rem) translateX(${
        subMenu.xPosition
      }rem)`,
    };
    return (
      <div className="navbar_menu_wrapper shadow" style={wrapperStyle}>
        <ul className="navbar_list_style">
          {subMenu.items.map((item) => (
            <LiItemGenerator key={item.id} item={item} />
          ))}
        </ul>
      </div>
    );
  };
  return (
    <>
      <ul className="navbar_list navbar_list_style">
        <li className="navbar_logo">
          <Link to="/">
            <img
              className={`navbar_logo_img ${
                showNavbarBg && "img_after_scroll"
              }`}
              src={molbioLogo}
              alt="mol-bio-lab"
            />
          </Link>
        </li>
        <li
          className="navbar_item navbar_item_margin"
          onMouseEnter={() => handleSetShowSubmenu(null)}
        >
          <Link to="/">Home</Link>
        </li>
        <li
          className="navbar_item navbar_item_margin"
          onMouseEnter={() => handleSetShowSubmenu("aboutUs")}
        >
          About Us
        </li>
        <li
          className="navbar_item navbar_item_margin"
          onMouseEnter={() => handleSetShowSubmenu("research")}
        >
          Research
        </li>
        <li
          className="navbar_item"
          onMouseEnter={() => handleSetShowSubmenu("newsAndEvents")}
        >
          <Link to="/">News & Events</Link>
        </li>
      </ul>
      <section className="navbar_menu_container">
        {showSubmenu && (
          <SubMenu key={menu[showSubmenu].id} subMenu={menu[showSubmenu]} />
        )}
      </section>
    </>
  );
};

export default MenuBarPC;
