import PublicationsC from "../components/Publications/Publications";

const Publications = () => {
  return (
    <>
      <main>
        <PublicationsC />
      </main>
    </>
  );
};

export default Publications;
